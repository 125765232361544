@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "champion-bantamweight";
		src: url('/assets/fonts/Champion-Bantamweight.woff2') format('woff2'),
			url('/assets/fonts/Champion-Bantamweight.woff') format('woff'),
			url('/assets/fonts/Champion-Bantamweight.ttf') format('truetype');
		font-style: normal;
		font-weight: 700;
		font-display: block;
	}
	@font-face {
		font-family: "garamond";
		src: url('/assets/fonts/gpp.ttf') format('truetype');
		font-style: normal;
		font-weight: 400;
		font-display: block;
	}
	@font-face {
		font-family: "garamond";
		src: url('/assets/fonts/gppi.ttf') format('truetype');
		font-style: italic;
		font-weight: 400;
		font-display: block;
	}
	@font-face {
		font-family: "garamond";
		src: url('/assets/fonts/gppb.ttf') format('truetype');
		font-style: normal;
		font-weight: 700;
		font-display: block;
	}
	@font-face {
		font-family: "garamond";
		src: url('/assets/fonts/gppbi.ttf') format('truetype');
		font-style: italic;
		font-weight: 700;
		font-display: block;
	}

	html {
		@apply font-body h-full
	}

	body {
		@apply bg-offwhite h-full
	}

	a {
		@apply cursor-pointer
	}

	h1, h2, h3 {
		@apply font-headings mb-6
	}


}


img.spinastar {
	animation-name: spinistar;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
}
img.spinastarev {
	animation-name: spinistarev;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
}

@keyframes spinistar { 
	0% {
		transform: rotate3d(0, 0, 0, 20deg) scaleX(1) scaleY(1);
		filter: blur(0.1px) brightness(1);
	}
	25% {
		transform: rotate3d(-.02, .1, .02, 20deg) scaleX(1) scaleY(1);
		filter: blur(0.05px) brightness(1.1);
	}
	50% {
		transform: rotate3d(0, .2, 0, 20deg) scaleX(1.05) scaleY(1.05);
		filter: blur(0.2px) brightness(1.35);
	}
	75% {
		transform: rotate3d(.02, .1, -.02, 20deg) scaleX(1) scaleY(1);
		filter: blur(0.05px) brightness(1.1) drop-shadow(0 0 0.35vmin gold);
	}
	100% {
		transform: rotate3d(0, 0, 0, 20deg) scaleX(1) scaleY(1);
		filter: blur(0.1px) brightness(1);
	}
	
}

@keyframes spinistarev {
	0% {
		transform: rotate3d(0, 0, 0, 20deg) scaleX(-1) scaleY(1);
		filter: blur(0.1px) brightness(1);
	}
	25% {
		transform: rotate3d(.02, .1, -.02, 20deg) scaleX(-1) scaleY(1);
		filter: blur(0.05px) brightness(1.1);
	}
	50% {
		transform: rotate3d(0, .2, 0, 20deg) scaleX(-1.05) scaleY(1.05);
		filter: blur(0.2px) brightness(1.35);
	}
	75% {
		transform: rotate3d(-.02, .1, .02, 20deg) scaleX(-1) scaleY(1);
		filter: blur(0.05px) brightness(1.1) drop-shadow(0 0 0.35vmin gold);
	}
	100% {
		transform: rotate3d(0, 0, 0, 20deg) scaleX(-1) scaleY(1);
		filter: blur(0.1px) brightness(1);
	}
	
}